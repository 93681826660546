import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { IError } from '../shared/interfaces';
import responseToaster from '../shared/helpers/response-toaster';

const useFetching = <T>(fetcher, key, shouldFetch = true) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [errorsCount, setErrorsCount] = useState(0);
  const router = useRouter();
  const { data, error, mutate } = useSWR<T, IError>(
    shouldFetch ? key : null,
    fetcher,
    {
      refreshInterval: 100000,
      errorRetryInterval: 5000,
    },
  );
  const isLoading = data === undefined && !error;

  if (error?.response?.data?.statusCode === 404) {
    router.push('/404');
  }

  useEffect(() => {
    if (error) {
      setErrorsCount((prevState) => prevState++);
      if (errorsCount > 2) {
        responseToaster({ error, addToast, t });
      }
    } else {
      setErrorsCount(0);
    }
  }, [error]);

  return { data, error, mutate, isLoading };
};
// eslint-disable-next-line import/prefer-default-export
export { useFetching };
